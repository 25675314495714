.icon-grid div.p-col-12 {
    color: #757575;
    text-align: center;
    padding: 16px;
    font-size: 12px;
}

.icon-grid i {
    display: block;
    margin: 0 auto;
    font-size: 24px;
}

pre {
    font-family: monospace;
    background-color: #0C2238;
    color: #dddddd;
    padding: 1em;
    font-size: 14px;
    border-radius: 3px;
    overflow: auto;
}

.shadow-box {
    background-color: #607D8B;
    width: 100px;
    height: 100px;
}
.docs h1 {
    margin-top: 30px;
}

.docs pre {
    font-family: monospace;
    background-color: #0C2238;
    color: #dddddd;
    padding: 1em;
    font-size: 14px;
    border-radius: 3px;
    overflow: auto;
}

.inline-code {
    background-color: #0C2238;
    color: #dddddd;
    font-style: normal;
    font-size: 13px;
    padding: 0 .5em;
}

.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

